import React from "react";
import { Container } from "react-bootstrap";

// markup
const CarFile = () => {
  return (
    <Container>
      <h1>Add car</h1>
    </Container>
  );
};

export default CarFile;
